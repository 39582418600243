<template>
    <v-data-table :headers="headers" :items="queryPreviewData.tableData" :sort-desc="[true, true]" hide-default-footer class="text-truncate">
        <template v-slot:top>
            <v-toolbar class="mb-3" flat color="white">
                <div class="d-flex align-center" style="width:100%">
                    <span class="primary--text font-weight-bold title">Query Preview</span>
                    <v-dialog v-model="sqlDialog" max-width="500">
                        <template v-slot:activator="{ on: dialog }">
                            <v-tooltip bottom>
                                <template v-slot:activator="{ on: tooltip }">
                                    <v-btn color="primary" icon>
                                        <v-icon color="primary" dark v-on="{ ...tooltip, ...dialog }">mdi-eye-outline</v-icon>
                                    </v-btn>
                                </template>
                                <span>View query</span>
                            </v-tooltip>
                        </template>

                        <v-card>
                            <v-card-title>
                                <div class="primary--text"><v-icon class="mr-1">mdi-script-text-outline</v-icon>SQL Text</div>
                            </v-card-title>
                            <v-divider class="mb-1"></v-divider>
                            <v-card-text>{{ queryPreviewData.sql }}</v-card-text>
                            <v-card-actions>
                                <v-spacer></v-spacer>
                                <v-btn color="primary" text @click="sqlDialog = false">close</v-btn>
                            </v-card-actions>
                        </v-card>
                    </v-dialog>
                    <v-spacer></v-spacer>
                    <QueryBuilderDialog
                        v-if="latestQueryBuilderQueryData && latestQueryBuilderQueryData.query === queryPreviewData.sql"
                        :listItem="false"
                        :setDataFromLastQuery="true"
                        @querySubmitSuccess="$emit('querySubmitSuccess')"
                        :outlinedActivationButton="true"
                        :smallActivationButton="false"
                        buttonName="open querybuilder"
                    />
                    <GetTableData :sqlText="queryPreviewData.sql" />

                    <v-tooltip bottom>
                        <template v-slot:activator="{ on }">
                            <v-btn color="primary" v-on="on" class="font-weight-bold ml-1 caption" outlined text :loading="loading" @click="saveFullResults">
                                <v-icon class="mr-1" small>save</v-icon>
                                save full results
                            </v-btn>
                        </template>
                        <span>Adds a CSV file to your files containing the full results of this query.</span>
                    </v-tooltip>
                </div>
            </v-toolbar>
        </template>
        <template v-slot:no-data>
            <v-alert text :value="true" color="info" icon="info" class="mt-3">
                No results for this query.
            </v-alert>
        </template>
    </v-data-table>
</template>
<script>
import { mapState } from 'vuex'

const GetTableData = () => import('../components/TheSnapshotTableGetData')
const QueryBuilderDialog = () => import('../components/TheSnapshotQueryBuilderDialog')

export default {
    name: 'TablePreview',
    components: {
        GetTableData,
        QueryBuilderDialog
    },
    data() {
        return {
            sqlDialog: false,
            loading: false,
            sqlQueryFromQueryBuilder: null
        }
    },
    computed: {
        ...mapState('snapshotStore', ['queryPreviewData', 'latestQueryBuilderQueryData']),
        headers() {
            var headers = []
            this.queryPreviewData.headers.forEach(row => {
                headers.push({ text: row, align: 'left', value: row })
            })
            return headers
        }
    },
    methods: {
        saveFullResults: function() {
            this.$data.loading = true
            if (this.queryPreviewData.sql) {
                const postBody = {
                    command: this.queryPreviewData.sql,
                    mode: 'FULL_WITH_SAVE',
                    snid: this.$route.params.snid
                }
                this.$store.dispatch('showSnackBar', {
                    snackBarText: 'Saving results.. a CSV files will be stored in your workspace files.',
                    snackBarTimeout: 10000,
                    snackBarIcon: 'info'
                })
                this.$axios
                    .post('/queries', postBody)
                    .then(response => {
                        this.$emit('queryFilesSaveSuccess', { value: true })
                    })
                    .catch(() => {
                        this.$store.dispatch('showSnackBar', {
                            snackBarText: 'Failed to submit the query, please retry later..',
                            snackBarTimeout: 10000,
                            snackBarIcon: 'error'
                        })
                    })
                    .finally(() => {
                        this.$data.loading = false
                    })
            }
        }
    }
}
</script>
